import Image from 'next/image';

export interface IHeader {
  className?: string;
  withBackground?: boolean;
}

export const Header = ({ className, withBackground = true }: IHeader) => {
  return (
    <header
      className={`mb-2.5 h-[75px] w-full bg-bottom bg-repeat-x ${className} ${
        withBackground && 'bg-header bg-header-bkg'
      }`}
    >
      <a
        href="/"
        className="absolute top-0 left-1/2 z-10 ml-[-34.5px] h-20 w-[69px]"
      >
        <Image
          src="/img/newdesign/v2/game/ui/logo-floating.png"
          alt="El Gran Truco Argentino"
          width={69}
          height={80}
        />
      </a>
    </header>
  );
};
