'use client';
import '../../globals.css';
import { Footer } from '@/app/truco/components/organisms/Footer';
import { Header } from '@/app/truco/components/organisms/Header';
import useFooter from '@/app/truco/hooks/useFooter';

export default function SiteTemplate({
  children,
  hideHeader = false,
  hideFooter = false,
}: {
  children: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
}) {
  const { columns } = useFooter();

  return (
    <main className="flex flex-col gap-3 bg-app bg-site-bkg bg-top bg-no-repeat px-4">
      {!hideHeader && <Header className="flex" withBackground={false} />}
      {children}

      {!hideFooter ? <Footer columns={columns} /> : null}
    </main>
  );
}
