'use client';
import '../globals.css';
import SiteTemplate from '../components/templates/SiteTemplate';

export default function DefaultTemplate({
  children,
}: {
  children: React.ReactNode;
}) {
  return <SiteTemplate hideHeader={true}>{children}</SiteTemplate>;
}
