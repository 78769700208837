import { IFooter } from '@/app/truco/components/organisms/Footer';
import { RANK_YEAR } from '@/constants';
import { ExternalUrls, PagesUrls } from '../utils/pageUrls';

export default function useFooter(): IFooter {
  return {
    columns: [
      {
        id: 0,
        title: 'El Gran Truco',
        className: 'text-primary [&>h4]:border-primary',
        links: [
          {
            id: 0,
            href: `${PagesUrls.RANKINGS}/global/${RANK_YEAR}`,
            label: `Mejores jugadores ${RANK_YEAR}`,
          },
          {
            id: 1,
            href: ExternalUrls.CHAT_SUPPORT,
            label: 'Soporte y Ayuda',
            className: 'hidden md:block',
          },
          {
            id: 2,
            href: PagesUrls.TRUCO_APP,
            label: 'Truco Argentino para celular',
          },
          {
            id: 3,
            href: PagesUrls.RULES,
            label: 'Normas de la comunidad',
          },
          {
            id: 4,
            href: PagesUrls.LEGALS,
            label: 'Términos y Condiciones',
          },
        ],
      },
      {
        id: 1,
        title: 'Redes Sociales',
        className: 'text-secondary [&>h4]:border-secondary hidden md:block',
        links: [
          {
            id: 0,
            href: 'https://www.instagram.com/grantrucoargentino/',
            label: 'Truco en Instagram',
          },
          {
            id: 1,
            href: 'https://www.facebook.com/elgrantruco',
            label: 'Truco en Facebook',
          },
          {
            id: 2,
            href: 'https://twitter.com/El_Gran_Truco',
            label: 'Truco en X',
          },
        ],
      },
      {
        id: 2,
        title: 'Jugar',
        className: 'text-disabled-light [&>h4]:border-disabled-light',
        links: [
          {
            id: 0,
            href: 'https://domino.cloudymedia.com',
            label: 'Dominó Online',
            target: '_blank',
            className: 'hidden md:block',
          },
          {
            id: 1,
            href: 'https://generala.cloudymedia.com',
            label: 'Generala Online',
            target: '_blank',
            className: 'hidden md:block',
          },
          {
            id: 2,
            href: '/pages/como-jugar-al-truco-argentino',
            label: 'Cómo jugar al Truco Argentino',
            target: undefined,
          },
          {
            id: 3,
            href: '/pages/reglamento-de-truco-argentino',
            label: 'Reglamento de Truco Argentino',
            target: undefined,
          },
          {
            id: 4,
            href: '/pages/tetris',
            label: 'Jugar Tetris Clásico Online',
            target: undefined,
            className: 'hidden md:block',
          },
        ],
      },
    ],
  };
}
