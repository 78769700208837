'use client';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconNames,
} from '@maestro/catarata';
import { useState } from 'react';
import classNames from 'classnames';

export interface IFooterLink {
  id: number;
  href: string;
  label: string;
  target?: string | undefined;
  className?: string | undefined;
}
export interface IFooterColumn {
  id: number;
  title: string;
  className: string;
  links: IFooterLink[];
}
export interface IFooter {
  columns: IFooterColumn[];
}

export const Footer = ({ columns }: IFooter) => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  const menuClasses = classNames(
    'bg-primary-xxdark fixed inset-0 px-4 pb-4 pt-16 md:my-6 md:relative md:inset-auto z-auto md:p-0 md:block md:bg-transparent',
    {
      hidden: !menuExpanded,
    },
  );

  return (
    <section className="relative z-50 px-4 text-center md:container md:z-auto md:mx-auto md:block md:max-w-[960px] md:text-left">
      <Button
        onClick={() => setMenuExpanded(!menuExpanded)}
        className="fixed top-4 right-4 z-50 md:hidden"
        variant={ButtonVariant.TERTIARY}
        size={ButtonSize.SMALL}
        icon={
          <Icon
            icon={menuExpanded ? IconNames.CLOSE : IconNames.INFO}
            className="h-5 w-5 text-primary-extralight"
          />
        }
      />
      <footer className={menuClasses}>
        <div className="flex flex-col gap-4 md:flex-row">
          {columns.map((column) => {
            return (
              <div
                className={`md:w-1/3 ${column.className}`}
                id="trucoLinks"
                key={`footer-col-${column.id}`}
              >
                <h4 className="rounded-full border-1 bg-surface-container px-3 py-0.5 text-md uppercase">
                  {column.title}
                </h4>
                <ul className="flex flex-col gap-2 px-2 py-3 text-sm">
                  {column.links.map((link) => {
                    return (
                      <li
                        key={`footer-col-${column.id}-${link.id}`}
                        className={link.className}
                      >
                        <a
                          href={link.href}
                          target={link.target}
                          rel="noopener"
                          className="md:hover:underline"
                        >
                          {link.label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </footer>
    </section>
  );
};
